import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import { Player } from '@vimeo/player';

import { MOBILE_BREAKPOINT } from "@util/constants";
import { Maybe } from "@graphql-types";

interface Props {
  url: Maybe<string> | undefined;
  pause?: boolean;
  play?: boolean;
  volume?: boolean;
  currentTime?: number | null | undefined;
  height?: string;
  width?: string;
  minWidth?: string;
  minHeight?: string;
  foregroundOpen?: boolean;
  autoPlayOnLoad?: boolean;
  isVisible?: boolean;
}

const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height:40%;
  }
`;

const StyledIframe = styled(Vimeo) <{
  foregroundOpen?: boolean | undefined;
  minHeight?: string | undefined;
  minWidth?: string | undefined;
}>`
  iframe {
    width: ${(props) => props.width ?? "100vw"};
    height: ${(props) => props.height ?? "56.25vw"};
    min-height: ${(props) => props.minHeight ?? "100vh"};
    min-width: ${(props) => props.minWidth ?? `250vh;`};
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}
  }
`;

function Video(props: Props) {
  const {
    url,
    volume,
    height,
    width,
    minHeight,
    minWidth,
    foregroundOpen,
    autoPlayOnLoad,
    isVisible,
  } = props;

  const [autoPlay, setAutoplay] = useState(autoPlayOnLoad);
  const playerRef = useRef<Player>();

  function onLoad() {
    if (autoPlay) {
      playerRef.current && playerRef.current.play();
    }
  }

  const onReady = (player: Player) => {
    playerRef.current = player;
  };

  useEffect(() => {
    if (isVisible) {
      playerRef.current ? playerRef.current.play() : setAutoplay(true);
    } else {
      playerRef.current && playerRef.current.pause();
    }
  }, [isVisible]);

  useEffect(() => {
    if (playerRef.current && foregroundOpen) {
      playerRef.current.setCurrentTime(0);
    }
  }, [foregroundOpen]);

  return (
    <VideoWrapper>
      <StyledIframe
        video={url as string}
        autoplay={true}
        background={true}
        muted={!volume}
        height={height}
        width={width}
        minHeight={minHeight}
        minWidth={minWidth}
        loop
        volume={volume ? 1 : 0}
        controls={false}
        onLoaded={onLoad}
        onReady={onReady}
        foregroundOpen={foregroundOpen}
      />
    </VideoWrapper>
  );
}

export default Video;
