import React from "react";
import styled from "styled-components";
import { AnchorLink as GatsbyAnchorLink } from "gatsby-plugin-anchor-links";

import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";

const StyledLink = styled(GatsbyAnchorLink)`
  color: ${colors.white};
  text-decoration: none;
  width: fit-content;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;
interface Props {
  title: Maybe<string> | undefined;
  baseSlug: string;
  anchor: Maybe<string> | undefined;
}

const AnchorLink = ({ title, baseSlug, anchor }: Props) => {
  const to = baseSlug === "/" ? `/#${anchor}` : `/${baseSlug}#${anchor}`;
  return <StyledLink to={to} title={title as string} />;
};

export default AnchorLink;
