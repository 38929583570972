import React from "react";

import Layout from "./src/shared/layout";
import "./static/fonts/index.css";

/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
// import "./static/fonts/fonts.css";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
