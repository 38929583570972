import {
  SanityColumn,
  SanityFlightImage,
  SanityFullWidthContent,
  SanityLeftRightBlock,
  SanityVideoUpload,
} from "@graphql-types";
import { assets, buttonStyle, colors } from "./constants";

export type Dimensions = {
  width?: string;
  height?: string;
};
export type FontFam = "bold" | "regular";
export type FontWeight = "bold" | "normal" | "light" | number;
export type FlexDirection =
  | "row"
  | "column"
  | "row-reverse"
  | "column-reverse"
  | "initial";
export type FlexWrap = "nowrap" | "wrap" | "wrap-reverse" | "initial";
export type JustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-around"
  | "space-between"
  | "space-evenly"
  | "initial";
export type AlignItems =
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "initial";
export type AlignSelf =
  | "auto"
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "initial";
export type Overflow = "auto" | "scroll" | "visible" | "hidden" | "initial";
export type PDisplayType = "inline" | "block" | "flex" | "initial";
export type Cursor = "grab" | "pointer" | "auto" | "initial";
export type WhiteSpace =
  | "initial"
  | "normal"
  | "nowrap"
  | "pre-line"
  | "pre-wrap";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ButtonTheme = keyof typeof buttonStyle;

export function isSanityFlightImage(data: any): data is SanityFlightImage {
  return (data as SanityFlightImage)._type === "flightImage";
}

export function isSanityVideoUpload(data: any): data is SanityVideoUpload {
  return (data as SanityVideoUpload)._type === "videoUpload";
}

export function isSanityFullWidthContent(
  data: any
): data is SanityFullWidthContent {
  return (data as SanityFullWidthContent)._type === "fullWidthContent";
}

export function isSanityLeftRightBlock(
  data: any
): data is SanityLeftRightBlock {
  return (data as SanityLeftRightBlock)._type === "leftRightBlock";
}

export function isSanityColumn(data: any): data is SanityColumn {
  return (data as SanityColumn)._type === "column";
}
