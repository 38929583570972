import { Maybe, SanityLink } from "@graphql-types";
import { useRef, useEffect, useState, Dispatch, SetStateAction } from "react";
import { ButtonTheme } from "./types";

export type StickyKeys = "langCode";

export const isBrowser = () => typeof window !== "undefined";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useStickyState<S>(
  defaultValue: S | (() => S),
  key: StickyKeys
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState(() => {
    const stickyValue = isBrowser() && window.localStorage.getItem(key);

    return stickyValue != null && stickyValue !== false
      ? JSON.parse(stickyValue)
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export function setStickyState(key: StickyKeys, value: any) {
  return isBrowser() && window.localStorage.setItem(key, JSON.stringify(value));
}

export function getStickyState(key: StickyKeys) {
  const stickyValue = isBrowser() && window.localStorage.getItem(key);
  return stickyValue != null && stickyValue !== false
    ? JSON.parse(stickyValue)
    : undefined;
}

export function arraysEquality(array1: any[], array2: any[]) {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

export function determineButtonTheme(
  theme: ButtonTheme,
  isOnDarkBackground?: boolean | undefined
) {
  if (isOnDarkBackground || isOnDarkBackground == null) {
    return theme;
  }
  switch (theme) {
    case "text":
      return "textDark";
    default:
      return theme;
  }
}

export function linkToButton(link: Maybe<SanityLink> | undefined) {
  if (link == null) return null;
  const { linktext, url, internallink, newwindow } = link;

  return {
    text: linktext,
    linkTo: internallink ? url : undefined,
    linkToNewWindow: newwindow ? url : undefined,
  };
}
