import * as React from "react";
import styled from "styled-components";

import { Maybe, SanityFullWidthContent } from "@graphql-types";
import {
  assets,
  colors,
  colorsRGB,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { Container } from "@util/standard";
import { BlocksContent, Button, Image, Video } from "@global";
import { isSanityFlightImage, isSanityVideoUpload } from "@util/types";
import { linkToButton } from "@util/helper";

const Wrapper = styled(Container)<{
  isHomepage?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background-color: ${colors.darkBlue};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${props => (props.isHomepage ? "667px" : "970px")};
  }
`;

const MediaContainer = styled(Container)<{ isHomepage?: boolean }>`
  height: 100%;
  width: 100%;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${props => (props.isHomepage ? "667px" : "40%")};
  }
`;

const ContentContainer = styled(Container)`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
  }
`;

const Overlay = styled.div<{
  isHomepage?: boolean;
}>`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background-color: ${colorsRGB.fullWidthOverlay(0.44)};
  overflow: hidden;
`;

const LayoutContainer = styled(Container)<{
  isHomepage?: boolean;
  reversed?: Maybe<boolean> | undefined;
}>`
  width: 25%;
  margin: ${props =>
    props.isHomepage
      ? `auto 14%;`
      : props.reversed
      ? `auto 15% auto 60%;`
      : `auto 20%;`};
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 60%;
    margin: auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 77%;
    flex-direction: column;
    margin: ${props =>
      props.isHomepage ? `auto auto 45px 45px;` : `auto auto 21% auto;`};
  }
`;

const LogoContainer = styled(Container)`
  margin: 1.5% 2% auto auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 15px 0;
  }
`;

const BlocksContainer = styled(Container)`
  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 70%;
  }
`;

interface Props {
  isHomepage?: boolean;
  data: SanityFullWidthContent | undefined;
}

interface MediaProps {
  fullWidthContent?: Maybe<SanityFullWidthContent>;
}

const FullWidthContent = ({ isHomepage, data }: Props) => {
  const RenderMedia = ({ fullWidthContent }: MediaProps) => {
    if (
      fullWidthContent?.backgroundMedia &&
      fullWidthContent?.backgroundMedia?.length > 0
    ) {
      const media = fullWidthContent.backgroundMedia[0];
      if (isSanityFlightImage(media)) {
        return (
          <Image
            key={media._key}
            image={media.asset?.gatsbyImageData}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        );
      }

      if (isSanityVideoUpload(media)) {
        return (
          <Video
            key={media._key}
            url={media.vimeoUrl}
            volume={false}
            pause={true}
            isVisible={true}
          />
        );
      }
    }
    return null;
  };

  return (
    <Wrapper isHomepage={isHomepage}>
      <Overlay isHomepage={isHomepage} />
      <MediaContainer isHomepage={isHomepage}>
        <RenderMedia fullWidthContent={data} />
      </MediaContainer>
      <ContentContainer>
        <LayoutContainer isHomepage={isHomepage} reversed={data?.textReverse}>
          {isHomepage && (
            <LogoContainer>
              <Image
                staticImage={assets.heroTriangle}
                width="29.5px"
                height="17px"
              />
            </LogoContainer>
          )}
          <BlocksContainer flexDirection="column">
            <BlocksContent
              blocks={data?.content?._rawColumnContent}
              key={data?.content?._key}
            />
            <Container margin="10px 0 0 0">
              {data?.blockLinks?.map(link => {
                const buttonLink = linkToButton(link);

                return (
                  <Button
                    {...buttonLink}
                    key={link?._key}
                    margin="0 40px 0 0"
                    padding="10px 40px"
                    dimensions={{ height: "46px" }}
                  />
                );
              })}
            </Container>
          </BlocksContainer>
        </LayoutContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default FullWidthContent;
