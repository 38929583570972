export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#001CA8",
  skyBlue: "#2EBFEC",
  grey: "#889AA4",
  hoverBlue: "#1193BB",
  darkBlue: "#0A0C11",
  anchorBC: "#0D061D",
};

export const PRIMARY_COLOR = colors.flightBlue;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 14, mobile: 13, innerHeight: 19.53, mobileHeight: 18.6 },
  h1: { default: 50, mobile: 35, innerHeight: 60, mobileHeight: 42.35 },
  h2: { default: 40, mobile: 30, innerHeight: 48, mobileHeight: 30 },
  h3: { default: 30, mobile: 25, innerHeight: 36, mobileHeight: 18.6 },
  h4: { default: 17, mobile: 15, innerHeight: 21, mobileHeight: 18.6 },
  button: { default: 12, mobile: 11, innerHeight: 14.4, mobileHeight: 13.2 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  lightBlack: (opacity?: number) => `rgba(23, 23, 23, ${opacity ?? "0.7"})`,
  heroBlack: (opacity?: number) => `rgba(8, 15, 25, ${opacity ?? "0.7"})`,
  fullWidthOverlay: (opacity?: number) =>
    `rgba(9, 18, 38, ${opacity ?? "0.7"})`,
  anchorBC: (opacity?: number) => `rgba(10, 12, 17, ${opacity ?? "0.7"})`,
  anchorPurple: (opacity?: number) => `rgba(13, 6, 29, ${opacity ?? "0.7"})`,
  quoteBC: (opacity?: number) => `rgba(12, 10, 28, ${opacity ?? "1"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  ctaArrow: require("@static/assets/cta-arrow.svg").default,
  ctaArrowDark: require("@static/assets/cta-arrow-dark.svg").default,
  close: require("@static/assets/close.svg").default,
  heroTriangle: require("@static/assets/triangle.png").default,
  plusLogo: require("@static/assets/plusLogo.png").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.skyBlue}`,
    text: `${colors.white}`,
    border: `${colors.skyBlue}`,
    hoverBg: `${colors.hoverBlue}`,
    hoverBorder: `${colors.hoverBlue}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.black}`,
  },
  grey: {
    bg: `${colors.grey}`,
    text: `${colors.white}`,
    border: `${colors.grey}`,
    hoverBg: `${colors.grey}`,
    hoverBorder: `${colors.grey}`,
    hoverText: `${colors.white}`,
  },
  unstyled: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.white}`,
    opacity: 0.6,
  },
  text: {},
  textDark: {},
};
