import React, { ReactNode } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage, BgImage } from "gbimage-bridge";
import styled from "styled-components";

const StyledImage = styled(GatsbyImage) <{
  width?: string;
}>`
  ${({ width }) => (width ? `width:${width}` : "width: 100%;")}
`;

const BackgroundGatsbyImage = styled(GatsbyImage) <{}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImageWrapper = styled.div<{ minHeight?: string; }>`
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

interface Props {
  image?: IGatsbyImageData;
  staticImage?: string;
  objectFit?: "contain" | "cover" | "fill" | "scale-down" | "none";
  minHeight?: string;
  isBackground?: boolean;
  backgroundStyle?: any;
  width?: string;
  height?: string;
  altText?: string;
  children?: ReactNode;
}

const Image = (props: Props) => {
  const { minHeight } = props;

  if (props.isBackground) {
    const bgImage = convertToBgImage(props.image);
    return (
      <BgImage preserveStackingContext {...bgImage}>
        <BackgroundImageWrapper minHeight={minHeight}>
          <BackgroundGatsbyImage
            image={props.image as IGatsbyImageData}
            alt="bg-image"
          />
          <BackgroundImageWrapper>{props.children}</BackgroundImageWrapper>
        </BackgroundImageWrapper>
      </BgImage>
    );
  }

  if (props.staticImage) {
    return (
      <img
        src={props.staticImage}
        width={props.width}
        height={props.height}
        style={{ minHeight }}
        itemProp="image"
        alt={props.altText ?? "image"}
      />
    );
  }

  if (props.image) {
    return (
      <StyledImage
        image={props.image}
        imgStyle={{
          objectFit: props.objectFit ?? "initial",
        }}
        style={{ minHeight }}
        width={props.width}
        itemProp="image"
        alt={props.altText ?? "gatsby-image"}
      />
    );
  }

  return null;
};

export default Image;
