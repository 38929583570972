import React, { ReactNode, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Header from "./header";
import Footer from "./footer";
import { fontSizes, MOBILE_BREAKPOINT } from "@util/constants";
interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "regular";
    font-size: ${fontSizes.p.default}px;
    line-height: ${fontSizes.p.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.p.mobile}px;
      line-height: ${fontSizes.p.mobileHeight}px;
    }
  }
  h1 {
    margin: 0px;
    font-family: "bold";
    font-size: ${fontSizes.h1.default}px;
    line-height: ${fontSizes.h1.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobileHeight}px;
    }
  }
  h2 {
    margin: 0px;
    font-family: "bold";
    font-size: ${fontSizes.h2.default}px;
    line-height: ${fontSizes.h2.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobileHeight}px;
    }
  }
  h3 {
    margin: 0px;
    font-family: "bold";
    font-size: ${fontSizes.h3.default}px;
    line-height: ${fontSizes.h3.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h3.mobile}px;
      line-height: ${fontSizes.h3.mobileHeight}px;
    }
  }

  h4 {
    margin: 0px;
    font-size: ${fontSizes.h4.default}px;
    line-height: ${fontSizes.h4.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h4.mobile}px;
      line-height: ${fontSizes.h4.mobileHeight}px;
    }
  }
    
  button {
    margin: 0px;
    font-family: "regular";
    font-size: ${fontSizes.button.default}px;
    line-height: ${fontSizes.button.innerHeight}px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.button.mobile}px;
      line-height: ${fontSizes.button.mobileHeight}px;
    }
  }
  
`;

const Main = styled.main<{}>``;

function Layout(props: Props) {
  const { children } = props;
  const [isContact, setIsContact] = useState(false);

  useEffect(() => {
    if (props.location.pathname.includes("/contact")) {
      setIsContact(true);
    } else {
      setIsContact(false);
    }
  });

  return (
    <>
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer isContact={isContact} />
    </>
  );
}

export default Layout;
