import * as React from "react";
import styled from "styled-components";

import { Maybe, SanityLeftRightBlock } from "@graphql-types";
import { BlocksContent, Button, Image, Link } from "@global";
import {
  colors,
  fontSizes,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { Container, P } from "@util/standard";
import { linkToButton } from "@util/helper";

const Wrapper = styled(Container)<{ isAbout?: boolean }>`
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: ${colors.darkBlue};
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: ${props => (props.isAbout ? "column" : "column-reverse;")};
    height: auto;
  }
`;

const ImageContainer = styled(Container)<{
  reversed: Maybe<boolean> | undefined;
  isStudyCase?: boolean;
}>`
  position: absolute;
  width: ${props => (props.isStudyCase ? "50%;" : "60%;")};
  height: 605px;
  right: ${props => (props.reversed ? "0;" : "auto;")};
  overflow: hidden;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    position: relative;
    height: 100%;
  }
`;

const TextContainer = styled(Container)<{
  reversed: Maybe<boolean> | undefined;
  isStudyCase?: boolean;
}>`
  position: relative;
  height: auto;
  flex-direction: column;
  width: ${props => (props.isStudyCase ? "35%;" : "20%;")};
  margin: ${props =>
    props.reversed ? "auto auto auto 9%;" : "auto 9% auto auto;"};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 75%;
    margin: 10% auto;
    justify-content: center;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20% auto;
  }
`;

const Title = styled(P)`
  color: white;
  font-size: ${fontSizes.p.default};
  text-transform: capitalize;
`;

interface Props {
  data?: Maybe<SanityLeftRightBlock> | undefined;
  isStudyCase?: boolean;
  isAbout?: boolean;
}

const LeftRightBlock = ({ data, isStudyCase, isAbout }: Props) => {
  if (data == null) {
    return null;
  }

  const Buttons = () => {
    if (data?.blockLinks && data?.blockLinks?.length > 0) {
      const firstButton = linkToButton(data?.blockLinks[0]);
      const secondButton = linkToButton(data?.blockLinks[1]);

      return (
        <Container flexDirection="row" margin="15px 0 0 0">
          <Button
            {...firstButton}
            margin="0 40px 0 0"
            padding="10px 40px"
            dimensions={{ height: "46px" }}
          />
          {secondButton && (
            <Button
              {...secondButton}
              theme="text"
              arrowWidth="15px"
              isNormalFont
              noTextUnderlineOffset
              underlined="underline 1px"
              mobilefontSize={11}
              arrowMargin=" auto auto auto 8px"
            />
          )}
        </Container>
      );
    }
    return null;
  };

  const SubTitles = () => {
    if (data?.subTitle && data?.subTitle?.length > 0) {
      const firstTitle = data?.subTitle[0];
      const secondTitle = data?.subTitle[1];
      return (
        <>
          <Link {...firstTitle} color="white" fontSize={14} />
          <Title margin="auto 5%">|</Title>
          <Link {...secondTitle} color="white" fontSize={14} />
        </>
      );
    }
    return null;
  };

  return (
    <Container flexDirection="column">
      <Wrapper isAbout={isAbout}>
        <ImageContainer reversed={data?.imageReverse} isStudyCase={isStudyCase}>
          <Image
            objectFit="cover"
            width="100%"
            height="100%"
            image={data?.backgroundImage?.asset?.gatsbyImageData}
          />
        </ImageContainer>
        <TextContainer reversed={data?.imageReverse} isStudyCase={isStudyCase}>
          <Title>{data?.content?.contentTitle}</Title>
          <BlocksContent blocks={data?.content?._rawColumnContent} />
          <Container flexDirection="row">
            <SubTitles />
          </Container>
          <Buttons />
        </TextContainer>
      </Wrapper>
    </Container>
  );
};

export default LeftRightBlock;
