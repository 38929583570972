import React from "react";

import { Maybe, SanityColumnOrFullWidthContentOrLeftRightBlock, } from "@graphql-types";
import { P } from "@util/standard";
import { isSanityColumn, isSanityFullWidthContent, isSanityLeftRightBlock } from "@util/types";
import QuoteBlock from "@shared/quoteBlock";
import FullWidthContent from "@shared/fullWidthContent";
import LeftRightBlock from "@shared/leftRightBlock";

interface Props {
  id: Maybe<string> | undefined;
  data: SanityColumnOrFullWidthContentOrLeftRightBlock | undefined;
  isStudyCase?: boolean;
}

const AnchoredContent = ({ data, id, isStudyCase }: Props) => {
  if (data == null) {
    return null;
  }
  if (isSanityColumn(data)) {
    return (
      <div id={id ?? ""}>
        <QuoteBlock quoteData={data} key={data._key} />
      </div>
    );
  }

  if (isSanityFullWidthContent(data)) {
    return (
      <div id={id ?? ""}>
        <FullWidthContent data={data} key={data._key} />
      </div>
    );
  }

  if (isSanityLeftRightBlock(data)) {
    return (
      <div id={id ?? ""}>
        <LeftRightBlock data={data} isStudyCase={isStudyCase} key={data._key} />
      </div>
    );
  }
  return <P>Error loading content...</P>;
};

export default AnchoredContent;
