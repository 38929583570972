import React from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

import { Button } from "@global";
import {
  colors,
  fontSizes,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
} from "@util/constants";
import { Container } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";
import { linkToButton } from "@util/helper";

interface Props {
  blocks: any;
  fontSize?: number;
  mobileFontSize?: number;
  pWidth?: string;
}

const Img = styled(Container)<{ url?: string }>`
  margin: 20px 0px 35px 0px;
  height: 700px;
  width: 100%;
  ${({ url }) => url && `background-image: url(${url})`};
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 600px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 500px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
    margin: 20px 0px;
  }
`;

const serializer = {
  types: {
    link: (props: any) => {
      const link = linkToButton(props.node);

      return (
        <Button
          {...link}
          theme="text"
          fontSize={12}
          underlined="underline 1px"
          arrowWidth="15px"
          arrowMargin=" auto auto auto 8px"
          isNormalFont
          noTextUnderlineOffset
        />
      );
    },
    image: (props: any) => {
      return <Img url={urlForImageRef(props.node.asset).url() as string} />;
    },
  },
};

const StyledPortableText = styled(PortableText)<{
  fontSize?: number;
  pWidth?: string;
  mobileFontSize?: number;
}>`
  li {
    color: white;
  }
  h1 {
    font-size: ${fontSizes.h1.default};
    color: white;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobile * 1.2}px;
    }
  }

  h2 {
    font-size: ${fontSizes.h2.default};
    color: white;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobile * 1.2}px;
    }
  }

  h3 {
    font-size: ${fontSizes.h3.default};
    color: white;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${props =>
        `${props.mobileFontSize}px` ?? `${fontSizes.h3.mobile}px;`};
      line-height: ${fontSizes.h3.mobile * 1.2}px;
    }
  }

  h4 {
    font-size: ${fontSizes.h4.default};
    color: white;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h4.mobile}px;
      line-height: ${fontSizes.h4.mobile * 1.2}px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: ${props => props.fontSize ?? fontSizes.p.default}px;
    color: white;
    width: ${props => props.pWidth ?? "100%"};
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${props => props.fontSize ?? fontSizes.p.default}px;
      line-height: ${fontSizes.p.mobileHeight}px;
      width: 100%;
    }
  }

  a {
    text-decoration-line: underline;
    color: ${colors.black};
  }
`;

const BlocksContent = ({ blocks, fontSize, pWidth, mobileFontSize }: Props) => {
  return (
    <StyledPortableText
      pWidth={pWidth}
      blocks={blocks}
      serializers={serializer}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      mobileFontSize={mobileFontSize}
      fontSize={fontSize}
    />
  );
};

export default BlocksContent;
