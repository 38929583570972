import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { useSpring, animated } from "react-spring";

import { ButtonTheme, Dimensions } from "@util/types";
import {
  assets,
  buttonStyle,
  fontSizes,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import Loading from "./loading";
import { Image } from "@global";
import { Container, GiveMeSomeSpace, P, A } from "@util/standard";
import { determineButtonTheme } from "@util/helper";
import { Maybe } from "@graphql-types";

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}
  height: ${props => props.dimensions?.height ?? `auto`};
  color: ${props => props.theme.text};
  padding: ${props => (props.padding ? props.padding : `0px`)};
  background-color: ${props => props.theme.bg};
  border: ${props => `2px solid ${props.theme.border}`};
  transition: background-color 0.4s linear;

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};
      background-color: ${theme.hoverBg};
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    font-size: ${fontSizes.button.mobile}px;
    padding: ${props => (props.padding ? props.padding : `5px 30px`)};
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
`;

const LinkBlock = styled(GatsbyLink)`
  &:link {
    text-decoration: none;
  }
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  text?: Maybe<string>;
  linkTo?: Maybe<string>;
  linkToNewWindow?: Maybe<string>;
  isOnDarkBackground?: boolean;
  width?: string;
  fontSize?: number;
  mobilefontSize?: number;
  arrowWidth?: string;
  isNormalFont?: boolean;
  noTextUnderlineOffset?: boolean;
  underlined?: string;
  arrowMargin?: string;
}

const Button = ({
  theme = "white",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
  isOnDarkBackground,
  fontSize,
  mobilefontSize,
  arrowWidth,
  isNormalFont,
  noTextUnderlineOffset,
  underlined,
  arrowMargin,
}: Props) => {
  const [styles, api] = useSpring(() => ({
    from: { x: 5, opacity: 1 },
  }));
  const [hover, setHover] = useState(false);
  const buttonTheme = determineButtonTheme(theme, isOnDarkBackground);
  const to = linkTo === "/" ? "/" : `/${linkTo}`;

  useEffect(() => {
    if (hover) {
      api.start({
        x: 15,
        opacity: 1,
      });
    } else {
      api.start({
        x: 5,
        opacity: 1,
      });
    }
  }, [hover]);

  const Loader = () => (
    <LoadingContainer>
      <>
        <Loading />
        <GiveMeSomeSpace space={3} />
        <P noMargin color="white">
          Loading..
        </P>
      </>
    </LoadingContainer>
  );

  const Text = () => (
    <P
      underlined={underlined}
      margin="0"
      userSelect="none"
      fontSize={fontSize}
      fontFamily={isNormalFont ? "regular" : "bold"}
      mobilefontSize={mobilefontSize}
      textUnderlinedOffset={noTextUnderlineOffset ? 0 : 2}
      color={buttonTheme === "text" ? "white" : "black"}
    >{`${text} `}</P>
  );

  const AnimatedArrow = () => (
    <animated.div
      style={{
        ...styles,
      }}
    >
      <Container
        margin={arrowMargin ? `${arrowMargin}` : "auto auto auto 32px"}
      >
        <Image
          staticImage={
            buttonTheme === "text" ? assets.ctaArrow : assets.ctaArrowDark
          }
          altText="arrow"
          width={arrowWidth ? `${arrowWidth}` : "40px"}
        />
      </Container>
    </animated.div>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({
    externalLink,
  }: {
    externalLink?: string | undefined;
  }) => {
    return (
      <StyledButton
        theme={buttonStyle[buttonTheme]}
        dimensions={dimensions}
        width={width}
        disabled={disabled}
        onClick={handleOnClick}
        margin={margin}
        padding={padding}
        mobileDimensions={mobileDimensions}
        disableHover={loading || disableHoverEffect}
        mobileMargin={mobileMargin}
        minHeight={minHeight}
        type={type}
        fontSize={fontSize}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {loading ? (
          <Loader />
        ) : externalLink ? (
          <A
            href={linkToNewWindow!}
            hoverColor="black"
            target="_blank"
            rel="noreferrer"
            color="white"
          >
            {text}
          </A>
        ) : (
          <div>{`${text} `}</div>
        )}
      </StyledButton>
    );
  };

  if (buttonTheme === "text" || buttonTheme === "textDark") {
    return (
      <Container
        display="inline-flex"
        cursor="pointer"
        alignItems="center"
        margin={margin}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleOnClick}
      >
        {linkToNewWindow ? (
          <A
            href={linkToNewWindow}
            hoverColor="black"
            target="_blank"
            rel="noreferrer"
            color="white"
          >
            <Text />
          </A>
        ) : (
          <LinkBlock to={to}>
            <Text />
          </LinkBlock>
        )}
        {buttonTheme === "text" && <AnimatedArrow />}
        {buttonTheme === "textDark" && <AnimatedArrow />}
      </Container>
    );
  }

  if (linkTo && theme !== "text" && !linkToNewWindow) {
    return (
      <GatsbyLink to={to}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  return <RenderedButton externalLink={linkToNewWindow!} />;
};

export default Button;
