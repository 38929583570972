import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import { assets, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { Image, Link } from "@global";
import OpenCloseIcon from "./sub/openCloseIcon";
import MenuDrawer from "./sub/menuDrawer";
import { Container } from "@util/standard";

const Wrapper = styled.header<{ hasScrolled?: boolean }>`
  width: 100%;
  position: fixed;
  background-color: ${props =>
    props.hasScrolled
      ? `${colorsRGB.lightBlack(1)};`
      : `${colorsRGB.lightBlack()};`};
  z-index: 4;
`;

const DeskHeaderContainer = styled(Container)`
  height: 62px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const LogoWrapper = styled(Container)`
  user-select: none;
  cursor: pointer;
  align-items: center;
  width: 40%;
`;

const DesktopLinksContainer = styled(Container)`
  margin: 0;
  width: auto;

  .link {
    padding-left: 40px;
  }
`;

const MobileHeaderContainer = styled(Container)`
  height: 62px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 9%;

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileLogoWrapper = styled(Container)`
  user-select: none;
  cursor: pointer;
  align-items: center;
  width: 70%;
`;

const Close = styled.img`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 25px;
  right: 25px;
  user-select: none;
  cursor: pointer;
`;

const MobileLinkContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 70%;
  height: 100%;
  margin: auto;
  justify-content: center;
`;

const MenuItem = styled(Container)`
  margin-bottom: 67px;
`;

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const isBrowser = () => typeof window !== "undefined";
  const { sanityNavigation }: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        logo {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        headerLinks {
          linktext
          url
          internallink
          _key
        }
      }
    }
  `);

  const handleScroll = () => {
    const scrollDistance =
      document.body.scrollTop + document.documentElement.scrollTop;

    if (scrollDistance > 10) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenMenu = () => {
    setDrawerVisible(true);
  };

  const handleCloseMenu = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    return () => {
      setDrawerVisible(false);
    };
  }, []);

  if (sanityNavigation == null) {
    return null;
  }

  const { logo, headerLinks } = sanityNavigation;
  const MobileMenu = () => {
    return (
      <MobileLinkContainer>
        {sanityNavigation.headerLinks?.map(menuItem => {
          const url = menuItem?.url === "/" ? "/" : `/${menuItem?.url}`;
          const isCurrent = isBrowser() && url === window.location.pathname;

          return (
            <MenuItem
              key={menuItem?._key}
              onClick={() => setDrawerVisible(false)}
            >
              <Link
                {...menuItem}
                color="white"
                fontFamily="bold"
                fontSize={20}
                isUnderlined={isCurrent}
                opacity={isCurrent ? 1 : 0.8}
              />
            </MenuItem>
          );
        })}
      </MobileLinkContainer>
    );
  };
  return (
    <Wrapper hasScrolled={hasScrolled}>
      <DeskHeaderContainer>
        <LogoWrapper>
          <GatsbyLink to="/">
            <Image
              image={logo?.asset?.gatsbyImageData}
              altText="logo-image"
              width="256px"
              height="21px"
            />
          </GatsbyLink>
        </LogoWrapper>
        <DesktopLinksContainer>
          {headerLinks?.map(item => {
            if (item == null) {
              return null;
            }
            const url = item?.url === "/" ? "/" : `/${item?.url}`;
            const isCurrent = isBrowser() && url === window.location.pathname;

            return (
              <Link
                {...item}
                key={item._key}
                padding="0px 20px"
                color="white"
                isUnderlined={isCurrent}
              />
            );
          })}
        </DesktopLinksContainer>
      </DeskHeaderContainer>
      <MobileHeaderContainer>
        <MobileLogoWrapper>
          <GatsbyLink to="/">
            <Image
              image={logo?.asset?.gatsbyImageData}
              altText="logo-image"
              width="205px"
              height="17px"
            />
          </GatsbyLink>
        </MobileLogoWrapper>
        <Container>
          <Container onClick={handleOpenMenu}>
            <OpenCloseIcon open={false} darkVersion={false} />
          </Container>
          <MenuDrawer
            visible={drawerVisible}
            onClose={handleCloseMenu}
            placement="right"
          >
            <Close
              alt="close-icon"
              src={assets.close}
              onClick={handleCloseMenu}
            />
            <MobileMenu />
          </MenuDrawer>
        </Container>
      </MobileHeaderContainer>
    </Wrapper>
  );
};

export default Header;
