import * as React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { colors, colorsRGB, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { SanityColumn, Maybe } from "@graphql-types";
import { BlocksContent } from "@global";


const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: ${colors.darkBlue};
  padding: 150px 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
    padding:50px 0;
  }
`;

const TextContainer = styled(Container)`
  color: white;
  align-items: center;
  opacity: 0.6;
  text-align: center;
  width: 57%;
  margin: auto;
  
  h1,h2,h3,h4{
    line-height: 40.5px !important;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 70%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 70%;
    margin: 20% auto;
    height: 100%;
    h1,h2,h3,h4{
      line-height: 27px !important;
      font-size: 20px;
    }
  }
`;

interface Props {
  quoteData?: Maybe<SanityColumn> | undefined;

}

const QuoteBlock = ({ quoteData }: Props) => {

  if (quoteData == null) {
    return null;
  }

  return (
    <Wrapper >
      <TextContainer>
        <BlocksContent
          blocks={quoteData?._rawColumnContent}
        />
      </TextContainer>
    </Wrapper>
  );
};

export default QuoteBlock;
