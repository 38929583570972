import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";

import { A, P } from "@util/standard";
import { Image } from "@global";
import { colors } from "@util/constants";
import { Color, FontFam } from "@util/types";

interface Props {
  linktext?: string | undefined | null;
  url?: string | undefined | null;
  internallink?: boolean | undefined | null;
  newwindow?: boolean | undefined | null;
  asset?: IGatsbyImageData | undefined | null;
  color?: Color;
  isUnderlined?: boolean;
  isBold?: boolean;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  download?: boolean;
  fontSize?: number;
  fontFamily?: FontFam;
  isCurrent?: boolean;
}

const StyledGatsbyLink = styled(GatsbyLink) <{
  padding?: string;
  underlined?: number;
  opacity?: number;
  fontFamily?: FontFam;
}>`
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  user-select: none;
  ${({ padding }) => padding && `padding: ${padding}`};
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const StyledP = styled(P) <{
  color?: Color;
  hoverColor?: Color;
  fontSize?: number;
  opacity?: number;
  isCurrent?: boolean;
}>`
  ${({ color }) => color && `color:${colors[color]}`};
  ${({ fontSize }) => fontSize && `font-size:${fontSize}px`};
  ${({ opacity }) => opacity && `opacity:${opacity}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  margin: 0;
  ${({ isCurrent }) => isCurrent && `text-decoration: underline white; text-underline-offset: 8px;`};
  &:hover {
    text-decoration: underline white;
    text-underline-offset: 8px;
  }
`;

const Link = ({
  linktext,
  url,
  internallink,
  newwindow,
  asset,
  color,
  hoverColor,
  isUnderlined,
  fontFamily,
  padding,
  opacity,
  download,
  fontSize,
}: Props) => {
  if (internallink) {
    const to = url === "/" ? "/" : `/${url}`;
    return (
      <StyledGatsbyLink
        activeClassName="active"
        to={to}
        padding={padding}
        underlined={isUnderlined ? 1 : 0}
        opacity={opacity}
      >
        {asset ? (
          <ImageWrapper>
            <Image image={asset} />
          </ImageWrapper>
        ) : (
          <StyledP
            hoverColor={hoverColor}
            color={color}
            fontFamily={fontFamily}
            fontSize={fontSize}
            opacity={opacity}
            isCurrent={isUnderlined}
          >
            {linktext}
          </StyledP>
        )}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
      color={color}
      opacity={opacity}
      underlined={isUnderlined}
      download={download}
    >
      {asset ? (
        <ImageWrapper>
          <Image image={asset} />
        </ImageWrapper>
      ) : (
        <StyledP>{linktext}</StyledP>
      )}
    </A>
  );
};

export default Link;
