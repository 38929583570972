import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Link, Image, Button } from "@global";
import { linkToButton } from "@util/helper";

const Wrapper = styled.footer`
  background-color: ${colors.darkBlue};
  padding: 110px 0;
`;

const InnerContainer = styled(Container)`
  width: 80%;
  margin: auto;
  justify-content: space-between;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const LeftColumnContainer = styled(Container)`
  flex-direction: column;
`;

const ButtonContainer = styled(Container)`
  margin-bottom: 64px;
`;

const LinksContainer = styled(Container)<{ noPadding?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const LogoContainer = styled(Container)`
  width: 147px;
  height: 69px;
`;

const RightColumnContainer = styled(Container)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const MobileContainer = styled(Container)`
  flex-direction: column;
  margin: 10px auto;
  width: 80%;
  @media only screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileButtonContainer = styled(Container)`
  margin-left: 5px;
  margin-bottom: 50px;
`;

const MobileLogoContainer = styled(LogoContainer)`
  margin-top: 20px;
`;

interface Props {
  isContact: boolean;
}

const Footer = ({ isContact }: Props) => {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        footerLinks {
          ...sanityLink
        }
        quickLink {
          ...sanityLink
        }
        footerLogo {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          altText
        }
        footerText
        resourceLink {
          ...sanityLink
        }
      }
    }
  `);

  if (sanityFooter == null) {
    return null;
  }
  const { footerLinks, quickLink, footerLogo, resourceLink } = sanityFooter;
  const buttonLink = linkToButton(quickLink);

  const LeftColumn = () => (
    <LeftColumnContainer>
      {!isContact && quickLink && (
        <ButtonContainer>
          <Button
            {...buttonLink}
            theme="text"
            fontSize={42}
            underlined="underline 2px"
          />
        </ButtonContainer>
      )}
      <LinksContainer>
        {footerLinks?.map(menuItem => {
          return (
            <Link
              {...menuItem}
              key={menuItem?._key}
              color="white"
              padding="0 50px 18px 0"
            />
          );
        })}
      </LinksContainer>
    </LeftColumnContainer>
  );

  const RightColumn = () => (
    <RightColumnContainer>
      <LogoContainer>
        <Image
          image={footerLogo?.asset?.gatsbyImageData}
          altText="test-image"
        />
      </LogoContainer>
      <Container>
        {resourceLink?.map(item => {
          return (
            <Link
              {...item}
              key={item?._key}
              color="white"
              padding="0 0 0 50px"
            />
          );
        })}
      </Container>
    </RightColumnContainer>
  );

  const MobileFooter = () => (
    <MobileContainer>
      {!isContact && quickLink && (
        <MobileButtonContainer>
          <Button
            {...buttonLink}
            theme="text"
            mobilefontSize={30}
            underlined="underline 2px"
          />
        </MobileButtonContainer>
      )}
      <LinksContainer>
        {footerLinks?.map(menuItem => {
          return (
            <Link
              {...menuItem}
              key={menuItem?._key}
              color="white"
              padding="0 0px 18px 0"
            />
          );
        })}
      </LinksContainer>
      <MobileLogoContainer>
        <Image
          image={footerLogo?.asset?.gatsbyImageData}
          altText="test-image"
        />
      </MobileLogoContainer>
      <Container>
        {resourceLink?.map(item => {
          return (
            <Link
              {...item}
              key={item?._key}
              color="white"
              padding="50px 75px 0 0"
            />
          );
        })}
      </Container>
    </MobileContainer>
  );

  return (
    <Wrapper>
      <InnerContainer>
        <LeftColumn />
        <RightColumn />
      </InnerContainer>
      <MobileFooter />
    </Wrapper>
  );
};

export default Footer;
